import React from 'react'
import styled from 'styled-components'

import { Grid } from './Grid/index.css'
import { ContactFormOuterContainer, ContactFormInnerContainer, ContactFormHeader, ContactFormContainer, ContactFormTitle } from './index.css'
import EnvelopeIcon from './EnvelopeIcon/index'
import Form from './Form/index'
import SvgIconClose from '../../assets/icons/icon-close';

const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  top: 160px;
  right: 8%;
  z-index: 999999;
  @media screen and (min-width: 768px) {
    right:3%;
    top: 40px;
  }
`

const ContactForm = ({ url, showForm = true, setShowForm, content, offset = 0, formHeaderHeight = 80, position = 'absolute' }) => {
  return(
    <>
      <ContactFormOuterContainer showForm={showForm}>
        <ContactFormInnerContainer>
          <ContactFormContainer id="contact-form" formHeaderHeight={formHeaderHeight}>
            <div>
              <Grid>
                <EnvelopeIcon />

                <ContactFormTitle>
                  <p className="title-header">{content.titleHeader}</p>
                  <p className="title-copy">{content.titleCopy}</p>
                </ContactFormTitle>
              </Grid>
              <Form url={url} content={content} />
            </div>
          </ContactFormContainer>
        </ContactFormInnerContainer>
        <CloseBtn>
          <SvgIconClose onClick={() => setShowForm(false)} />
        </CloseBtn>
      </ContactFormOuterContainer>
    </>
  )
}

export default ContactForm
