import React, { useState } from 'react'
import { Formik } from 'formik';

import { CountriesList } from './countriesList'

import { Grid } from '../Grid/index.css'

import {
  FormContainer,
  InputLeft,
  InputRight,
  SelectRight,
  TextAreaFull,
  SubmitButton,
  ErrorMessage,
  ErrorMessageMobile,
  SubmitMessage
} from './index.css'
import PrivacyPolicy from './PrivacyPolicy/index'
import LegalAgreement from './LegalAgreement/index'
import LargeButton from '../Blocks/index'

const Form = ({ url, content }) => {
  const [error, setError] = useState(false)
  const numbers = CountriesList;
  const listItems = numbers.map((item, i) =>
    <option value={item.code} key={i}>{item.name}</option>
  );
  return(
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        message: content.hasMessageBox ? '' : 'No message required',
        termsAccepted: false,
      }}
      validate={values => {
        const errors = {}
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (
            values.country === ""
        ) {
          errors.country = 'Please select a country';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email.toLowerCase(),
              country: values.country,
              message: values.message,
              termsAccepted: values.termsAccepted,
              category: content?.category,
              projectCode: content?.projectCode
            })
          })
          setError(false)
          setSubmitting(false);
        }
        catch (e) {
          setError(true)
        }
      }}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitCount,
      }) => (
        <FormContainer action="" onSubmit={handleSubmit}>
        <Grid style={{ marginTop: "1rem" }}>
          <InputLeft
            type="text"
            id="fname"
            name="firstName"
            placeholder="First Name"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
          />
          <InputRight
            type="text"
            id="lname"
            name="lastName"
            placeholder="Last Name"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
        </Grid>

        <Grid style={{ marginTop: "1rem" }}>
          <InputLeft
            type="text"
            id="email"
            name="email"
            placeholder="E-mail"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email && <ErrorMessageMobile>{errors.email}</ErrorMessageMobile>}
          <SelectRight
            id="country"
            name="country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
            >
            <option hidden value="">Country</option>
            {listItems}
          </SelectRight>
          {errors.country && touched.country && <ErrorMessage>{errors.country}</ErrorMessage>}

        </Grid>

        {content.hasMessageBox && <Grid style={{ marginTop: "1rem" }}>
          <TextAreaFull
            id="message"
            name="message"
            placeholder="Message"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
          >
          </TextAreaFull>
        </Grid>}

        <PrivacyPolicy content={content} />

        <LegalAgreement
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.termsAccepted}
          content={content}
        />
        <Grid>
          {(submitCount === 0 || error) &&
            <SubmitButton>
              <LargeButton
              buttonContent={content.buttonCopy}
              color={"#F06C35"}
              type="submit"
              disabled={Object.keys(touched).length === 0 || Object.keys(errors).length !== 0}/>
            </SubmitButton> }
           {error && <SubmitMessage>There was an error submitting your details</SubmitMessage>}
           {submitCount > 0 && !error &&  <SubmitMessage>Thank you, the form was submitted successfully</SubmitMessage>}
        </Grid>
        </FormContainer>
      )}
      </Formik>
  )
}

export default Form
