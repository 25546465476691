import React from 'react'
import styled from 'styled-components'

import { Grid } from '../../Grid/index.css'

const PrivacyPolicyContainer = styled.div`
  grid-column: span 4 / span 4;
  background: white;
  color: #646464;
  border-radius: 2px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;

  @media screen and (min-width: 1024px) {
    grid-column: span 8 / span 8;
    grid-column-start: 3;
  }

  .privacypolicy-title {
    font-size: 14px;
    font-weight: bold;
  }

  .privacypolicy-copy {
    font-size: 12px;
    margin-top: 0.6rem;
  }

  a, span {
    color: #F06C35;
    cursor: pointer;
    text-decoration: underline;
  }
`

const PrivacyPolicy = ({ content }) => {
  return(
    <Grid>
      <PrivacyPolicyContainer>
        <p className="privacypolicy-title" dangerouslySetInnerHTML={{ __html: content.privacyPolicyTitle }}></p>
        <p className="privacypolicy-copy" dangerouslySetInnerHTML={{ __html: content.privacyPolicyCopy1 }}></p>
        <p className="privacypolicy-copy" dangerouslySetInnerHTML={{ __html: content.privacyPolicyCopy2 }}></p>
        <p className="privacypolicy-copy" dangerouslySetInnerHTML={{ __html: content.privacyPolicyCopy3 }}></p>
        <p className="privacypolicy-copy" dangerouslySetInnerHTML={{ __html: content.privacyPolicyCopy4 }}></p>
      </PrivacyPolicyContainer>
    </Grid>
  )
}

export default PrivacyPolicy 