import React, { lazy, Suspense, useState, useEffect } from "react";
import { contentUrl } from './config/api';
import styled from "styled-components/macro";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactForm from "./components/ContactForm";
import showWarning from "./utils/showWarning";
import {
  HEADER_CONTENT,
  LEAVING_DISCLAIMER,
  CONTACTFORM_CONTENT,
  FOOTER_CONTENT,
  MOBILELANDSCAPEOVERLAY_CONTENT,
} from "./config/app";
import {
  LeavingDisclaimer,
  MobileLandscapeOverlay,
  Alert,
  VideoFlyThrough,
  vcUiUtils,
  vcUiUtilHooks,
  mediaQueries
} from "@lxlabs/vc-ui-components";
const ThreeDScene = lazy(() => import("./components/ThreeDScene"));
import { assetsUrl } from "./config/api";
import {
  useCustomPopupActions,
  useCustomPopupState,
} from "./reducers/customPopup";

const { setLocalStorage, getLocalStorage } = vcUiUtils;
const { useGa } = vcUiUtilHooks;
const { gaContentViewed, gaPercentageVideoViewed } = useGa;

function App() {
  const customPopupSetters = useCustomPopupActions();
  const customPopupState = useCustomPopupState();
  const [showFlyThroughVideo, setShowFlyThroughVideo] = useState(
    !getLocalStorage("FlyThroughSeen")
  );

  useEffect(() => {
    if (!showFlyThroughVideo) {
      setLocalStorage("FlyThroughSeen", "true");
    }
  }, [showFlyThroughVideo]);

  const showFlythrough = false; // QUICK FIX TO REMOVE FLYTHROUGH - DELETE WHEN NEW VIDEO FLYTHROUGH IS RECEIVED

  return (
    <>
      <Suspense fallback={<></>}>
        <Header content={HEADER_CONTENT} />

        <div style={{ position: "relative" }}>
          <Alert
            message="For an optimal experience, please switch your browser to Google Chrome."
            iosMessage="For an optimal experience, please switch your browser to Safari."
            closeText="OK"
            show={showWarning()}
            menuBg={""}
            logoSymbol={""}
          />

          <MobileLandscapeOverlay
            content={MOBILELANDSCAPEOVERLAY_CONTENT.overlayMessage}
            mobileLandscapeImage={`${assetsUrl}/images/mobile-landscape-overlay.svg`}
          />

          {showFlythrough && showFlyThroughVideo ? ( // QUICK FIX TO REMOVE FLYTHROUGH - DELETE showFlythrough WHEN NEW VIDEO FLYTHROUGH IS RECEIVED
            <>
              <VideoFlyThroughContainer className="VideoFlyThroughContainer">
                <p
                  className="skip"
                  onClick={() => setShowFlyThroughVideo(false)}
                >
                  SKIP
                </p>

                <VideoFlyThrough
                  src={`${assetsUrl}/videos/Flythrough-Video.mp4`}
                  onEnd={() => {
                    setShowFlyThroughVideo(false)
                  }}
                  renderCallback={(cb) => {
                    gaContentViewed({ type: 'VIDEO', name: 'videoFlyThrough'})
                    gaPercentageVideoViewed({ video: cb?.ref?.current, name: 'videoFlyThrough'})
                  }}
                />
              </VideoFlyThroughContainer>
              <ThreeDScene />
            </>
          ) : (
            <ThreeDScene />
          )}

          <LeavingDisclaimer
            setShowModal={() => {
              customPopupSetters.setLeavingDisclaimerOpen(false);
            }}
            showModal={customPopupState.leavingDisclaimerOpen}
            disclaimer={{
              href: customPopupState.leavingDisclaimerHref,
            }}
            disclaimerCopy={LEAVING_DISCLAIMER}
          />

          <ContactForm
            showForm={customPopupState.contactUsOpen}
            url={process.env.REACT_APP_CONTACT_US_ENDPOINT}
            content={CONTACTFORM_CONTENT}
            position={"fixed"}
            setShowForm={() => {
              customPopupSetters.setContactUsOpen(false);
            }}
          />
        </div>

        <Footer content={FOOTER_CONTENT} />
      </Suspense>
    </>
  );
}

export default App;

const VideoFlyThroughContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  position: absolute;
  z-index:  999999;
  @media screen and (max-width: 480px) { 
    margin-top: 50px;
  }
  .skip {
    bottom: 5%;
    color: white;
    font-size: 1.25rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity 0.5s ease-in-out 0.75s;
    text-shadow: 0 0 4px black;
    z-index: 100;
    :hover {
      color: #f9f9f9;
      cursor: pointer;
      font-size: 1.3rem;
    }
  }
  video { 
    height: calc(100vh - 130px - 100px);
    @media screen and (max-width: 480px) {
      height: calc(100vh - 160px);
      width: 100%;
    }
  }
`;
