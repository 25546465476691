import React from 'react'

import { Grid } from '../../Grid/index.css'
import { LegalAgreementContainer, LegalAgreementCheckbox } from './index.css'

const LegalAgreement = ({ onChange, onBlur, value, content }) => {
  return(
    <>
      <Grid>
        <LegalAgreementContainer>
          <p className="legalagreement-copy" dangerouslySetInnerHTML={{ __html: content.legalAgreementCopy }}></p>
        </LegalAgreementContainer>

        <LegalAgreementCheckbox>
          <input
            type="checkbox"
            id="legalagreement"
            name="termsAccepted"
            required
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            />
          <label htmlFor="legalagreement" dangerouslySetInnerHTML={{ __html: content.legalAgreementCheckbox }}></label>
        </LegalAgreementCheckbox>
      </Grid>
    </>
  )
}

export default LegalAgreement 