import React from 'react'
import styled from 'styled-components'

const LargeButtonContainer = styled.button`
  background: #F06C35;
  border-radius: 23px;
  border: ${props => props.secondaryColor ? `2px solid #F06C35` : "none"};
  box-shadow: 0px 4px 4px 0px #2D3742;
  cursor: pointer;
  color: ${props => props.secondaryColor ? `#F06C35` : "white"};
  height: 46px;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  padding: 0 3rem;
  :hover {
    transform: scale(1.02);
    transition: 0.2s;
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
    :hover {
      transform: none;
    }
  }
`

const LargeButton = ({ buttonContent, color, secondaryColor, onClick, disabled = false }) => {
  return(
    <LargeButtonContainer color={color} secondaryColor={secondaryColor} onClick={onClick} disabled={disabled}>
      {buttonContent}
    </LargeButtonContainer>
  )
}

export default LargeButton