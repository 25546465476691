export const HEADER_CONTENT = {
  menu: {
    logo: {
      src: 'https://s3.eu-west-2.amazonaws.com/uploads.eadv.hlxcongress.digital/assets/images/Dupixent-Logo.png',
      alt: 'logo',
    },
    items: [
      {
        cta: 'FOR HEALTHCARE PROFESSIONALS ONLY',
        plain: true,
      },
      {
        cta: 'Summary of Product Characteristics',
        subNav: [
          {
            header: 'Summary of Product Characteristics',
          },
          {
            cta: 'Summary of Product Characteristics - English',
            link: 'https://www.ema.europa.eu/en/documents/product-information/dupixent-epar-product-information_en.pdf',
            disclaimer: 'false'
          },
        ],
      },
      {
        cta: 'Indication',
        subNav: [
          {
            cta:
              'DUPIXENT is indicated to treat adults and adolescents 12 years and older with moderate-to-severe atopic dermatitis, and children with severe atopic dermatitis aged 6–11 years old who are candidates for systemic therapy.',
          },
        ],
      },
      {
        cta: 'Keep in touch',
        event: 'showForm',
        hideOnMobile: true,
      },
      {
        cta: 'Medical Information',
        hideOnMobile: true,
        event: 'showDisclaimer',
        href: 'https://www.regeneron.com/medical-inquiries',
      },
    ],
  },
}
