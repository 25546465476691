import React, { useRef } from 'react';
import { Container, Wrapper, Link, LinkLeft, Row, Col } from './index.css';

const Footer = ({ content }) => {
  const { footer } = content;
  const showLink = true;
  const containerRef = useRef(null);

  return (
    <Container ref={containerRef}>
       <Wrapper>
        {showLink && (
          <LinkLeft
            onClick={(e) => {
              e.preventDefault();
              containerRef.current.scrollIntoView();
            }}
          >
            Read More
          </LinkLeft>
        )}
        {showLink && (
          <Link
            onClick={(e) => {
              e.preventDefault();
              containerRef.current.scrollIntoView();
            }}
          >
            Read More
          </Link>
        )}
        <Row>
          <Col dangerouslySetInnerHTML={{ __html: footer.row1.col1 }}></Col>
          <Col dangerouslySetInnerHTML={{ __html: footer.row1.col2 }}></Col>
        </Row>
        <Row className="border">
          <Col dangerouslySetInnerHTML={{ __html: footer.row2.col1 }}></Col>
          <Col dangerouslySetInnerHTML={{ __html: footer.row2.col2 }}></Col>
        </Row>
        <Row>
          <Col className="single" dangerouslySetInnerHTML={{ __html: footer.row3.col1 }}></Col>
        </Row>
      </Wrapper>
    </Container>
  );
}

export default Footer;
