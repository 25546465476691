import styled from "styled-components";
import { assetsUrl } from "../../config/api";
import {mediaQueries} from "@lxlabs/vc-ui-components";

const {MOBILE} = mediaQueries;

export const Container = styled.div`
  width: 100%;
  height: max-content;
  background: #fff;
  font-family: "DIN";
`;

export const Wrapper = styled.div`
  width: calc(100% - 30px);
  height: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 15px;
  position: relative;

  @media (min-width: 992px) {
    max-width: 1170px;
  }
`;

export const Link = styled.div`
  display: block;
  position: absolute;
  right: 10px;
  color: #11b07e;
  font-weight: 700;
  font-size: 18px;
  padding-right: 30px;
  cursor: pointer;

  &:after {
    content: "";
    background-color: #11b07e;
    display: inline-flex;
    background-size: 12px;
    border-radius: 50%;
    background-image: url("${assetsUrl}/images/arrow-down-white.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: 2px;
    height: 14px;
    width: 14px;
    position: absolute;
    right: 5px;
    top: -1px;
  }
`;

export const LinkLeft = styled.div`
  display: block;
  position: absolute;
  left: 340px;
  color: #11b07e;
  font-weight: 700;
  font-size: 18px;
  padding-right: 30px;
  cursor: pointer;

  &:after {
    content: "";
    background-color: #11b07e;
    display: inline-flex;
    background-size: 12px;
    border-radius: 50%;
    background-image: url("${assetsUrl}/images/arrow-down-white.png");
    background-repeat: no-repeat;
    background-position: center;
    padding: 2px;
    height: 14px;
    width: 14px;
    position: absolute;
    right: 5px;
    top: -1px;
  }

  @media ${MOBILE} {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-top: 30px;

  @media ${MOBILE} {
    padding-top: 0;
  }

  p {
    font-size: 12px;

    @media (min-width: 992px) {
      font-size: 15px;
    }
  }

  &.border {
    border-bottom: 1px solid #eee;
    
    @media (min-width: 992px) {
      align-items: center;
      
      .row {
        align-items: center;
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

  }

  @media (min-width: 992px) {
    flex-direction: row;
  }

  &.reverse {
    flex-direction: column-reverse;

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
`;

export const Col = styled.div` 
  width: 100%;
  padding-right: 0;
  color: #796E65;
  font-size: 16px;

  &:last-child {
    border-left: 0;
    width: 100%;
    padding-left: 0;
  }

  &.single {
    padding-top: 50px;
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 40%;
    padding-right: 15px;

    &:last-child {
      border-left: 1px solid #eee;
      width: 60%;
      padding-left: 15px;
    }

    &.single {
      width: 100%;
      border-left: 0;
    }

  }

  .text-green,
  a {
    color:  #11B07E;
  }

  p {
    margin: 10px 0;
  }

  .h3 {
    margin: 0 0 5px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
  }

  .isi {
    padding-top: 5px;
    font-size: 17px;
    margin-bottom: 60px;
    line-height: 1.3;
  }

  .hideOnDesktop {
    display: inline;

    @media (min-width: 992px) {
      display: none;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .col {
      padding-bottom: 20px;
      padding-right: 0;
    }

    @media (min-width: 992px) {
      flex-direction: row;


      .col {
        padding-bottom: 0;
        padding-right: 50px;
      }
    }
  }
}
{`;
