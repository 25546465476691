import React from 'react'
import styled from 'styled-components'

const EnvelopeContainer = styled.div`
  grid-column: span 12 / span 12;
`

const EnvelopeImg = styled.img`
  margin: 0 auto;
  display: block;
`

const EnvelopeIcon = () => {
  return(
    <EnvelopeContainer>
      <EnvelopeImg src={'https://s3.eu-west-2.amazonaws.com/uploads.eadv.hlxcongress.digital/assets/images/envelope-bubble.png'} alt="envelope-bubble" />
    </EnvelopeContainer>
  )
}

export default EnvelopeIcon