import styled from  'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4,minmax(0,1fr));
  gap: 1rem;
  max-width: 90%;
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    max-width: 1200px;
    width: 90%;
    grid-template-columns: repeat(12,minmax(0,1fr));
  }
`