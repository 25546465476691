import React from 'react';
import { Container, Wrapper, Logo, Nav, Dropdown } from './index.css';
import { useCustomPopupActions } from '../../reducers/customPopup';

const renderSubNav = ({ header, cta, link, disclaimer = 'true' }) => {
	if (header) {
		return (
			<>
				<hr />
				<span className="header">{header}</span>
			</>
		);
	}
	if (link) {
		return (
			<span className="link">
				<a href={link} target="_blank" rel="noreferrer" data-disclaimer={disclaimer}>
					{cta}
				</a>
			</span>
		);
	}

	return <span className="text">{cta}</span>;
};

const events = {
	showForm: (customPopupSetters) => {
		customPopupSetters.setContactUsOpen(true);
	},
	showDisclaimer: (customPopupSetters, href) => {
		customPopupSetters.setLeavingDisclaimerOpen(true);
		customPopupSetters.setLeavingDisclaimerHref(href);
	}
}

export default function Header({ content }) {
	const { menu } = content;
	const { logo, items } = menu || {};
	
	const customPopupSetters = useCustomPopupActions();
	return (
		<Container>
			<Wrapper>
				<Logo>
					{logo && (
						<a href={logo.link}>
							<img src={logo.src} alt={logo.alt} />
						</a>
					)}
				</Logo>

				<Nav>
					{items &&
						items.map(({ plain, hideOnMobile, cta, event, subNav, singleLink, i, href }) => {
							let className = 'navItem';

							if (plain) {
								className += ' plain';
							}

							if (hideOnMobile) {
								className += ' hideOnMobile';
							}

							if (singleLink) {
								className += ' singleLink';
							}

							{
							if (events[event] && typeof events[event] === 'function') {
									return (
										<span 
											className="hideOnMobile singleLink"
											onClick={() => {events[event](customPopupSetters, href)}}
										>
											{cta}
										</span>
									)
								} 
							}

							return (
								<span className={className} key={i}>
									{singleLink ? <a href={singleLink}>{cta}</a> : cta}
									{subNav && <Dropdown>{subNav.map(renderSubNav)}</Dropdown>}
								</span>
							);
						})}
				</Nav>
			</Wrapper>
		</Container>
	);
}
