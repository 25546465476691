import styled from 'styled-components';
import {assetsUrl} from '../../config/api.js'
import { mediaQueries } from "@lxlabs/vc-ui-components";
const { MOBILE } = mediaQueries;


export const Container = styled.div`
  width: 100%;
  background: white;
  border-top: 4px solid #11B07E;
  font-family: 'DIN';
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
  }

`;

export const Wrapper = styled.div`
  width: 100%;
  height: 46px;
  background: #EEE;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media (min-width: 992px) {
    background: white;
    height: 126px;
    max-width: 1170px;
  }
`;

export const Logo = styled.div`
  display: none;
  align-self: center;

  img {
    width: 273px;
  }

  @media (min-width: 992px) {
    display: flex;
  }
`;

export const Nav = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  line-height: 1;


  .hideOnMobile {
    display: none;
  }

  span {
    cursor: pointer;
    font-size: 12px;
    padding: 8px 12px;
    color: #796E65;
    align-items: center;
    display: flex;
    position:relative;
    @media ${MOBILE} {
      position:static;
    }



    &.navItem:after {
      content: '';
      min-height: 10px;
      min-width: 10px;
      display: inline-block;
      background-size: contain;
      display: inline-flex;
      margin-left: 5px;
      background-image: url('${assetsUrl}/images/arrow-down-grey.png');
      background-repeat: no-repeat;
      background-position: center;
    }

    &.plain {
      cursor: default;
      font-weight: bold;

      &:after {
        display: none;
      }

      &:hover {
        background: none;
      }
    }

    &.singleLink {
      cursor: pointer;
      a {
        color: #796E65;
        text-decoration: none;
      }

      &:hover {
        background: #EEE;
      }
    }

    &:hover {
      background: #EEE;

    }

    &:hover div,
    &:active div {
      display: block;
    }

    &:hover,
    &:active {
      &.navItem:after {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  @media (min-width: 768px) {
    line-height: 1.5;
  }

  @media (min-width: 992px) {
    height: fit-content;
    justify-content: flex-end;

    span {
      font-size: 12px;

      &:after {
        width: 10px;
        height: 10px;
      }
    }

    .hideOnMobile {
      display: flex;
    }
  }
`;

export const Dropdown = styled.div`
  display: none;
  width: auto;
  max-width: 360px;
  padding-bottom: 5px;
  margin-top: 46px;
  position: absolute;
  left: calc(100% - 250px);
  top: 0;
  background: #EEE;
  cursor: default;
  z-index: 999999;

  @media ${MOBILE} {
    left: 0;
    width: 100%;
    max-width: 100%;
  }


  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
    left: 0;

  }
  @media (min-width: 992px) {
    margin-top: 34px;
    left: auto;
    right: 0;
  }

  span {
    padding: 0 12px 0 24px;
    margin: 13px 0;
    font-size: 14px;
    cursor: default;
  }

  .link {

    a {
      content: '';
      background-size: 14px;
      display: inline-block;
      margin-top: -7px;
      width: 320px;
      padding-right: 10px;
      background-image: url('${assetsUrl}/images/download.svg');
      background-repeat: no-repeat;
      background-position: 95% center;
      color: #796E65;
      text-decoration: none;  
    }
  }
}

.text {
  width: 320px;

  @media ${MOBILE} {
    width: 87%;
  }
}

.header {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;

  &:after {
    display: none;
  }
}

hr {
  border-top: 1px solid #DBD9D9;
  margin: 6px 15px 20px;
  padding: 1px 0;

  &:first-child {
    display: none;
  }
}
`;
