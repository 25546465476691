import styled from  'styled-components'

export const FormContainer = styled.form`
  input, select, textarea {
    ::placeholder {
      color: #333333;
    }
    color: #333333;
    padding: 12px;
  }
`

export const InputLeft = styled.input`
  grid-column: span 4 / span 4;
  @media screen and (min-width: 1024px) {
    grid-column: span 4 / span 4;
    grid-column-start: 3;
  }
`

export const ErrorMessage = styled.p`
  color: #F06C35;
  grid-column: span 8 / span 8;
  grid-column-start: 3;
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`

export const ErrorMessageMobile = styled.p`
  color: #F06C35;
  grid-column: span 4 / span 4;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const SubmitMessage = styled.p`
  grid-column: span 4 / span 4;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin: 0 auto;
  color: #FFF;
  text-align: center;
  @media screen and (min-width: 1024px) {
    grid-column: span 12 / span 12;
  }
`

export const InputRight = styled.input`
  grid-column: span 4 / span 4;
  @media screen and (min-width: 1024px) {
    grid-column: span 4 / span 4;
    grid-column-end: 11;
  }
`

export const SelectRight = styled.select`
  appearance: none;
  background: url('') calC(100% - 12px) no-repeat #fff;
  background-size: 16px 10px;
  grid-column: span 4 / span 4;
  @media screen and (min-width: 1024px) {
    grid-column: span 4 / span 4;
    grid-column-end: 11;
  }
`

export const TextAreaFull = styled.textarea`
  height: 100px;
  grid-column: span 4 / span 4;
  @media screen and (min-width: 1024px) {
    grid-column: span 8 / span 8;
    grid-column-start: 3;
  }
`

export const SubmitButton = styled.div`
  margin: 0 auto;
  grid-column: span 4 / span 4;
  @media screen and (min-width: 1024px) {
    grid-column: span 12 / span 12;
  }
`