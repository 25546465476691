const variables = {
  colors: {
    primary: "#049B77",
    primaryHover: "#0C8A62",
    primaryDark: "#0A5C42",
    secondary: "#FF9933",
    secondaryHover: "#C97928",
    secondaryDark: "#C97928",
    tertiary: "",
    tertiaryHover: "",
    tertiaryDark: "",
    white: "#FFF",
    black: "#000",
    lightgrey: "#F5F5F5",
    grey: "#DBD9D9",
    darkgrey: "#796E65",
  },
}

const alert = {
  alertBgPrimary: variables.colors.primary,
  alertBgSecondary: variables.colors.secondary,
  alertBgTertiary: variables.colors.grey,
  alertBgTertiaryHover: variables.colors.white,

  alertBorderPrimary: variables.colors.primary,
  alertBorderSecondary: variables.colors.secondary,

  alertTextPrimary: variables.colors.primary,
  alertTextSecondary: variables.colors.secondary,
}

const buttons = {
  buttonBgPrimary: variables.colors.primary,
  buttonBgSecondary: variables.colors.white,

  buttonBorderPrimary: variables.colors.primary,
  buttonBorderSecondary: variables.colors.white,

  buttonTextPrimary: variables.colors.primary,
  buttonTextSecondary: variables.colors.white,
}

const loadingScreen = {
  loadingScreenBorderBottom: '#FFCD02',
  loadingScreenFont: '',
  loadingScreenLineHeight: '1.1'
}

const maskSlider = {
  maskSliderTextPrimary: variables.colors.primary,
}

const videoWithTimeline = {
  videoWithTimelineBgPrimary: variables.colors.primary,
}

const navigation = {
  navBgPrimary: variables.colors.primary,
  navBgPrimaryHover: variables.colors.primaryHover,
  navBgPrimaryActive: variables.colors.white,

  navTabActive: variables.colors.secondary,
  navTabInactive: variables.colors.primary,
  navTabPrimaryActive: variables.colors.white,

  navTextPrimary: variables.colors.white,
  navTextSecondary: variables.colors.primary,
  navTextTertiary: variables.colors.darkgrey,

  navSkew: "135deg",
}

const scrollbar = {
  scrollbarBgGradient: "#75c34e, #009b77, #187c2e",
  scrollbarThumb: "#97d700",
}

const theme = {
  alert,
  buttons,
  loadingScreen,
  maskSlider,
  navigation,
  videoWithTimeline,
  scrollbar,
}

export default theme
