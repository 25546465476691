export const CONTACTFORM_CONTENT = {
  titleHeader: "Let's continue the conversation",
  titleCopy: "To stay in contact with us and learn more about our congress activities, please fill out the information below",
  privacyPolicyTitle: "INFORMATION & CONSENT FOR THE PROCESSING OF PERSONAL DATA",
  privacyPolicyCopy1: "If you consent to receiving marketing/promotional and medical educational communications, Sanofi-Aventis Group and its <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/list-of-sanofi-affiliates' target='_blank'>affiliates</a> will need to process your personal data to be able to send you personalized commercial and medical communications. To do this, Sanofi processes your interests, preferences and interactions in order to adapt these communications. For instance, the e-mails sent to you are equipped with click rating to determine whether a topic is of interest to you. It is also used to stop sending you messages where you have not opened any Sanofi communications for several months.",
  privacyPolicyCopy2: "To that end, your personal data may be communicated to processors used by Sanofi and/or to Sanofi’s alliance partner, Regeneron Pharmaceuticals Inc. Where these communications imply transfers of data outside of the region of collection, adequate safeguards are implemented such as Standards Contractual Clauses.",
  privacyPolicyCopy3: "Sanofi will keep these personal data as long as it has interactions with you, but no longer than 3 years after our last interaction, unless you withdraw your consent.",
  privacyPolicyCopy4: "According to applicable data protection law, you may have the rights to withdraw your consent via the methods outlined in the <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/list-of-sanofi-affiliates' target='_blank'>Privacy Policy</a>. You can ask access or portability to your personal data, ask for their rectification or erasure or restrict their processing. To exercise these rights, please <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/contact' target='_blank' rel='referrer'>click here</a>.",
  legalAgreementCheckbox: "I have read and understood the <a href='https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy/list-of-sanofi-affiliates' target='_blank'>Privacy Policy</a> and <a href='https://www.type2congress.com/6d42c37b-050a-4f0f-8ed8-942264d42b96/de0f105c-62ea-4f8d-a17f-bcd8ec9d9b05/7c96efc1-9244-4322-bedd-e7d5a1dc09a7/docs/Legal-notice.pdf' target='_blank'>Terms of Use</a> and I consent to the processing of my personal data to receive personalized commercial and medical communications from Sanofi, as described in the Privacy Policy.",
  buttonCopy: "SUBMIT",
  category: 'commercial',
  projectCode: 'eadv'
}
