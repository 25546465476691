import styled from 'styled-components'

export const ContactFormOuterContainer = styled.div`
  display: ${props => props.showForm ? "block" : "none"};
  z-index: 999998;
  @media screen and (min-width: 768px) {
    background: rgba(0, 0, 0, 0.7);
    /* height: calc(100% - 230px); */
    height: 100%;
    position: absolute;
    width: 100%;
    /* top: 130px;  */
    top: 0;
  }
`

export const ContactFormInnerContainer = styled.div`
  background: white;
  height: 100%;
  width: 100%; 
  @media screen and (min-width: 768px) {
    left: 7%;
    position: absolute;
    width: 86%;
    height: 90%;
    margin-top: 2.5%;
  }
`

export const ContactFormHeader = styled.div`
  background: white;
  height: ${props => props.formHeaderHeight}px;
  position: ${props => props.position};
  z-index: 999998;

  @media screen and (min-width: 768px) {
    position: relative;
  }

  .contactform_header {
    align-items: center;
    color: #796E65;
    display: flex;
    height: ${props => props.formHeaderHeight}px;
    justify-content: center;
    
    a, .anchor {
      color: #FF5454;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

export const ContactFormContainer = styled.div`
  background: #4B5B6C;
  position: absolute;
  padding: 0 0 2rem 0;
  z-index: 99998;
  width: 100%;
  height: calc(100% - 180px);
  overflow: auto;
  top: 140px;
  

  @media screen and (max-width: 768px) {
    width:90%;
    border-left: 20px solid #ffffff;
    border-right: 20px solid #ffffff;
    border-bottom: 20px solid #ffffff;
  }

  @media screen and (min-width: 768px) {
    height: calc(100% - 210px);
    top: 30px;
    height: calc(100% - 32px - 110px);
    width: calc(100% - 60px);
    left: 30px;
    padding: 2rem 0 calc(2rem + 28px) 0;
  }
}
`

export const ContactFormTitle = styled.div`
  grid-column: span 12 / span 12;
  color: white;
  text-align: center;

  .title-header {
    font-size: 26px;
    font-weight: bold;
    margin-top: 0;
    
    @media screen and (min-width: 1024px) {
      font-size: 30px;
    }
  }

  .title-copy {
    font-size: 16px;
    margin-top: 6px;

    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
  }
`
